@value input-height from '~styles/sizes.module.css';

.input {
  appearance: none;
  font-size: var(--font-size-14);
  font-family: var(--font-family__primary);
  height: input-height;
  padding: 10px 10px 0 16px;
  transition: 0.2s ease all;
  width: 100%;
  box-shadow: none;
  border: none;
}

.input:-webkit-autofill + label {
  transform: translateY(-8px);
  font-size: var(--font-size-12);
}

.input:focus {
  outline: 0;
}

.group {
  position: relative;
}

.group label {
  position: absolute;
  top: calc(50% - 10px);
  font-size: var(--font-size-14);
  left: 16px;
  transition: all 200ms ease;
  color: var(--payment-details__payment-input-group-color);
  pointer-events: none;
}

.group input:not(:placeholder-shown) + label,
.group input:focus + label {
  transform: translateY(-8px);
  font-size: var(--font-size-12);
  top: calc(50% - 10px);
}

.inputWrapper {
  position: relative;
}

.error {
  background: white;
  font-size: var(--font-size-12);
  padding: 0 16px 6px;
  pointer-events: none;
}

.input[type='number']::-webkit-inner-spin-button,
.input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.inputContainer {
  flex-basis: 100%;
  border-bottom: var(--payment-details__payment-input-container-border);
}
.smallContainer {
  flex-basis: 32%;
  border-right: var(--payment-details__payment-input-container-border);
}

.icon {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 8px;
}
