@value medium from '~styles/breakpoints.module.css';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  justify-content: space-between;
}

.main {
  min-height: 100vh;
  overflow: hidden;
}

@media medium {
  .main {
    min-height: 650px;
  }
}
