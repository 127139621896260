@value medium from '~styles/breakpoints.module.css';

.container {
  position: relative;
}

.header {
  background: var(--brand-bg);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  border-bottom: 1px solid #e9ecef;
}

.logo {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
}

.title {
  color: var(--text-on-background);
  font-size: 14px;
  flex: 1 1;
  display: flex;
  justify-content: center;
}

.spacer {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
}

.info {
  margin-bottom: 25px;
}

.tryAgain {
  margin-top: 16px;
}

.generate {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.field {
  margin-bottom: 8px;
}

.notFoundButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
