@value medium from '~styles/breakpoints.module.css';

.container {
  margin: 0 auto;
  max-width: 600px;
}

.title {
  composes: headline5 from global;
  font-size: 24px;
  line-height: 48px;
  margin-bottom: 24px;
  text-align: center;
}

.text {
  color: var(--font-family__color);
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 1.25rem;
}

@media medium {
  .title {
    font-size: 32px;
  }

  .text {
    font-size: 18px;
    line-height: 27px;
  }
}

@keyframes progressAnimation {
  0% {
    width: 0%;
  }

  100% {
    width: 95%;
  }
}

.progress {
  border-radius: 30px;
  background: #e9ecef;
  background-color: var(--progress-bar-bg);
}

.progressBar {
  height: 8px;
  border-radius: 30px;
  background-size: 200% 200%;
  background-color: var(--progress-bar__indicator-bg);
  width: 95%;
  animation: progressAnimation 30s;
}
