@value apply-header-drawer, apply-header from '~styles/zindices.module.css';
@value medium from '~styles/breakpoints.module.css';
@value apply-header-height from '~styles/sizes.module.css';

.progressContainer {
  background: var(--step-header__progress-bg);
  height: 5px;
  border-bottom: var(--step-header__border);
}

.progressInner {
  background: var(--step-header__progress);
  width: 0;
  height: 5px;
  transition: width 0.5s;
}

.container {
  background: var(--brand-bg);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: apply-header;
}

.heading {
  display: flex;
  align-items: center;
  height: apply-header-height;
  width: 100%;
}

.logoContainer {
  flex: 0 0 33.333%;
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
}

.progress {
  flex: 0 0 33.333%;
  display: flex;
  justify-content: center;
}

.chat {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  flex: 1;
}

.drawer {
  position: absolute;
  z-index: apply-header-drawer;
  display: flex;
  justify-content: center;
  margin-top: -16px;
  width: 100%;
}

.header {
  color: var(--text-on-background);
  line-height: 15px;
  text-align: center;
}

.name {
  font-size: 14px;
}

.step {
  color: var(--step-header__sub-text-color);
  font-size: 8px;
  text-transform: uppercase;
}

.expand {
  padding: 16px;
  width: 100%;
  transition: all 0.2s ease-in;
  max-height: 70vh;
  overflow: scroll;
  overflow-x: none;
}

.collapse {
  transition: all 0.2s ease-out;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transform-origin: top;
}

.progressTrackerHeading {
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 4px;
}

.editItems {
  margin-top: 24px;
}

.editEntity {
  margin-bottom: 16px;
}

.editEntity:last-child {
  margin-bottom: 0;
}

.editEntityHeader {
  display: none;
}

@media medium {
  .editItems {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 8px;
    padding: 16px;
  }

  .editEntity {
    width: 320px;
    margin: 0 16px;
  }

  .expand {
    padding: 0;
    overflow: hidden;
  }

  .editEntityHeader {
    display: block;
    font-size: 10px;
    font-family: var(--font-family__secondary);
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--step-header__sub-text-color);
    text-transform: uppercase;
    padding-bottom: 4px;
  }

  .chatIcon {
    display: none;
  }
}

.stepHeaderLogo {
  max-height: var(--step-header__logo-max-height);
  max-width: var(--step-header__logo-max-width);
}

@media print {
  .heading,
  .content {
    display: none;
  }
}
