@value loadingBar from '~styles/animations.module.css';

.loading1 {
  composes: loadingBar;
  height: 30px;
  width: 300px;
  margin-bottom: 16px;
}

.loading2 {
  composes: loadingBar;
  width: 200px;
  height: 18px;
  margin-bottom: 8px;
}
