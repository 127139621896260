@value medium from '~styles/breakpoints.module.css';

.main {
  composes: body2 from global;

  font-size: var(--disclaimer-font-size);
  line-height: var(--line-height-12);

  background: var(--disclaimer-bg);
  border-radius: var(--border-radius);
  color: var(--disclaimer-color);
  display: flex;
  padding: 8px 12px;
  margin-top: 8px;
}

.main img {
  height: 16px;
  margin: 1px 8px 0 0;
}

.center {
  text-align: center;
}

@media medium {
  .main {
    margin-top: 12px;
  }
}

.main.warning {
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: var(--disclaimer-warning-font-size);
  line-height: var(--disclaimer-warning-line-height);
  font-family: var(--font-family__primary);

  background: var(--disclaimer-warning-bg);
  color: var(--disclaimer-warning-color);

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 20px;
  }
}
