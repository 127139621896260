.caption {
  color: var(--font-family__color);
  font-family: var(--font-family__primary);
  font-weight: 400;
}

.footnote {
  color: var(--font-family__color);
  font-family: var(--font-family__primary);
  font-weight: 400;
}

.body3 {
  color: var(--font-family__color);
  font-family: var(--font-family__primary);
  font-weight: 400;
}

.body2 {
  color: var(--font-family__color);
  font-family: var(--font-family__primary);
  font-weight: 400;
}

.body1 {
  color: var(--font-family__color);
  font-family: var(--font-family__primary);
  font-weight: 400;
}

.global-subtitle {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 400;
}

.global-title {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
}

.headline5 {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
}
.headline4 {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
}
.headline3 {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
}
.headline2 {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
}
.headline1 {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
}

.headline {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
}

.display2 {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
}

.display1 {
  color: var(--font-family__headline-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
}

.font-primary {
  font-family: var(--font-family__primary);
}
.font-secondary {
  font-family: var(--font-family__secondary);
}
.font-mono {
  font-family: var(--font-family__mono);
}

/* modifiers */
.inverted {
  color: var(--brand-02);
}

.color-black {
  color: var(--black);
}

.color-gray-70 {
  color: var(--gray-70);
}

.color-gray-60 {
  color: var(--gray-60);
}

.color-gray-50 {
  color: var(--gray-50);
}

.color-gray-10 {
  color: var(--gray-10);
}

.color-gray-80 {
  color: var(--gray-80);
}

.color-gray-90 {
  color: var(--gray-90);
}

.color-headline {
  color: var(--font-family__headline-color);
}
.color-white {
  color: var(--white);
}
.color-brand-3 {
  color: var(--brand-03);
}
.color-brand-1 {
  color: var(--brand-01);
}

.font-mono {
  font-family: var(--font-family__mono);
}

/* Same in all themes */

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}
@media (min-width: 576px) {
  .text-left-md {
    text-align: left !important;
  }
  .text-center-md {
    text-align: center;
  }
}
.text-center {
  text-align: center;
}

@media (min-width: 796px) {
  .text-align-left-md {
    text-align: left !important;
  }
}

.font-size-10 {
  font-size: var(--font-size-10);
  line-height: var(--line-height-10);
}
.font-size-12 {
  font-size: var(--font-size-12);
  line-height: var(--line-height-12);
}
.font-size-14 {
  font-size: var(--font-size-14);
  line-height: var(--line-height-14);
}
.font-size-16 {
  font-size: var(--font-size-16);
  line-height: var(--line-height-16);
}
.font-size-18 {
  font-size: var(--font-size-18);
  line-height: var(--line-height-18);
}
.font-size-20 {
  font-size: var(--font-size-20);
  line-height: var(--line-height-20);
}
.font-size-24 {
  font-size: var(--font-size-24);
  line-height: var(--line-height-24);
}
.font-size-28 {
  font-size: var(--font-size-28);
  line-height: var(--line-height-28);
}
.font-size-30 {
  font-size: var(--font-size-30);
  line-height: var(--line-height-30);
}
.font-size-32 {
  font-size: var(--font-size-32);
  line-height: var(--line-height-32);
}
.font-size-64 {
  font-size: 64px;
  line-height: 88px;
}

@media (min-width: 576px) {
  .font-size-10-md {
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
  }
  .font-size-12-md {
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
  }
  .font-size-14-md {
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
  }
  .font-size-16-md {
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
  }
  .font-size-18-md {
    font-size: var(--font-size-18);
    line-height: var(--line-height-18);
  }
  .font-size-20-md {
    font-size: var(--font-size-20);
    line-height: var(--line-height-20);
  }
  .font-size-24-md {
    font-size: var(--font-size-24);
    line-height: var(--line-height-24);
  }
  .font-size-28-md {
    font-size: var(--font-size-28);
    line-height: var(--line-height-28);
  }
  .font-size-30-md {
    font-size: var(--font-size-30);
    line-height: var(--line-height-30);
  }
  .font-size-32-md {
    font-size: var(--font-size-32);
    line-height: var(--line-height-32);
  }
  .font-size-64-md {
    font-size: 64px;
    line-height: 88px;
  }
}

.text-align-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.list-style-none {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-style-inside {
  list-style-position: inside;
}
