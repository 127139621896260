@value medium from '~styles/breakpoints.module.css';

.container {
  background: rgb(33, 37, 41);
  border-radius: 6px;

  height: 170px;
  width: 346px;
  max-width: calc(100vw - 32px);
  padding: 16px;
}
.title {
  color: rgb(255, 255, 255);
  font-family: var(--font-family__secondary);
  font-size: 14px;
  font-weight: 600;
  width: 302px;
  margin-bottom: 5px;
}
.content {
  color: rgb(255, 255, 255);
  font-family: var(--font-family__primary);
  font-size: 14px;
  font-weight: normal;
  width: 314px;
}
.control {
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 16px;
}
.cta {
  color: rgb(255, 208, 6);
  font-family: var(--font-family__primary);
  font-size: 14px;
  font-weight: 600;
  height: 17px;
  text-align: center;
  max-width: 80px;
}
@media medium {
  .container {
    height: 96px;
    width: 482px;
    left: calc(50% - 241px);
  }
  .title {
    width: 373px;
    font-size: 16px;
  }
  .content {
    width: 373px;
  }
  .control,
  .cta {
    height: 100%;
  }
}
