@value medium from '~styles/breakpoints.module.css';

.policy {
  background: var(--payment-details__policy-bg);
  box-shadow: var(--payment-details__policy-box-shadow);
  border: var(--payment-details__policy-border);
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 16px;
}

.invalid {
  border: 2px solid var(--alert-10);
}

.signature {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.checkbox {
  font-size: var(--font-size-12);
  display: flex;
  align-items: center;
  margin-right: 6px;
  justify-content: center;
}

.description,
.description p {
  color: var(--payment-details__description-color);
  font-size: var(--font-size-12);
  line-height: var(--line-height-12);
  margin: 0;
}

.termsButton,
.description a {
  color: var(--payment-details__link-color);
  font-family: var(--font-family__secondary);
  font-weight: 500;
  font-size: var(--font-size-12);
  line-height: var(--line-height-12);
}

.content {
  margin-top: 24px;
  text-align: center;
  max-width: 550px;
}

.content p {
  color: var(--gray-60);
  font-size: var(--font-size-14);
  line-height: var(--line-height-14);
  margin-bottom: 8px;
}

.content a {
  color: var(--gray-60);
  text-decoration: underline;
}

/* modals */
.header {
  display: flex;
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

.document {
  font-size: 13px;
  margin-bottom: 12px;
  padding: 0px 16px;
  color: var(--gray-60);
}

.link {
  color: var(--button-link-color);
}

.formModal p {
  color: var(--gray-60);
}

.formModal {
  width: 100%;
  padding: 50px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.formModalInner {
  justify-content: center;
  align-items: center;
}

.formModalActions {
  text-align: right;
}

.formModalActionsPrint {
  text-align: left;
  display: flex;
  align-items: center;
}

.printIcon {
  margin-right: 5px;
}

.logo {
  width: 160px;
  margin-bottom: 40px;
}

@media medium {
  .formModal {
    width: 50%;
    min-width: 700px;
    max-width: 1000px;
    padding: 37px 24px 24px;
  }
}

.documentTitle {
  font-weight: bold;
  font-size: 18px;
}

.print {
  display: none;
}

@media print {
  .title {
    display: none;
  }
  .policy {
    display: none;
  }
  .dueTodayContainer {
    display: none;
  }

  .print {
    background: #fff;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 9999;
  }
}

.icon {
  display: flex;
  justify-content: center;
}
