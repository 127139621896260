.group {
  position: relative;
}

.defaultOption {
  display: none;
}

.select {
  appearance: none;
  cursor: pointer;
  height: var(--input-height);
  width: 100%;
  background-image: var(--select-input-bg-image);
  background-origin: content-box;
  background-color: var(--select-input-bg);
  background-position: var(--select-background-position);
  background-repeat: no-repeat;
  background-size: var(--select-background-size);
  border: var(--select-input-border);
  border-radius: var(--select-input-border-radius);
  box-shadow: var(--select-input-box-shadow);
  font-family: var(--font-family__primary);
  font-size: var(--font-size-16);
  padding: 10px 10px 0;
}

.flat {
  box-shadow: var(--select-input-box-shadow--flat);
  border: var(--select-input-border--flat);
  color: var(--select-input-color--flat);
}

.select:focus {
  outline: 0;
  border: var(--input-border-focus);
}

.label {
  color: var(--select-input__label-color);
  font-size: var(--select-input__label-font-size);
  font-family: var(--font-family__primary);
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 200ms ease;
  text-transform: capitalize;
}

.selected + .label {
  font-size: var(--select-input__label-font-size--selected);
  top: 12px;
}

.select:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.error {
  color: var(--alert-10);
  font-size: var(--font-size-10);
  margin-top: 4px;
  margin-bottom: 8px;
}
