.checkbox {
  font-size: 12px;
}

.label {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
}

.icon {
  position: relative;
}

.box {
  align-items: center;
  background: white;
  border: 1px solid #dee2e6;
  border-radius: var(--check-icon-border-radius-radius);
  margin-right: 8px;
  height: var(--checkbox-size);
  width: var(--checkbox-size);
}

.check {
  border-radius: var(--check-icon-border-radius-radius);
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transition: 0.15s ease-in-out;
}

.input:focus + .label .box {
  border: var(--checkbox__box-border--focus);
  box-shadow: var(--checkbox__box-box-shadow--focus);
}
.input:focus + .label .check {
  background: var(--checkbox__box-bg--focus);
  transform: var(--checkbox__check-transform--focus);
  border-radius: var(--check-icon-border-radius-radius);
}

.input:checked + .label .check {
  background: var(--check-icon-bg);
  transform: scale(1);
}

.input:focus,
.input:checked + .label .check {
  border: var(--checkbox__check-border--checked);
}

.input {
  position: absolute;
  opacity: 0;
}

.error .box {
  border: 1px solid var(--alert-10);
}
