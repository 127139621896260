.pb-64 {
  padding-bottom: var(--spacing-64);
}

/*Margin*/
.mt-auto {
  margin-top: auto;
}
.mb-0 {
  margin-bottom: var(--spacing-0);
}
.m-8 {
  margin: var(--spacing-8);
}
.mt-8 {
  margin-top: var(--spacing-8);
}
.ml-8 {
  margin-left: var(--spacing-8);
}
.mb-4 {
  margin-bottom: var(--spacing-4);
}
.mb-8 {
  margin-bottom: var(--spacing-8);
}
.mb--42 {
  margin-bottom: -42px;
}
.mr-8 {
  margin-right: var(--spacing-8);
}
.my-8 {
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-8);
}
.mx-8 {
  margin-left: var(--spacing-8);
  margin-right: var(--spacing-8);
}
.m-12 {
  margin: var(--spacing-12);
}
.mt-12 {
  margin-top: var(--spacing-12);
}
.ml-12 {
  margin-left: var(--spacing-12);
}
.mb-12 {
  margin-bottom: var(--spacing-12);
}
.mr-12 {
  margin-right: var(--spacing-12);
}
.my-12 {
  margin-top: var(--spacing-12);
  margin-bottom: var(--spacing-12);
}
.mx-12 {
  margin-left: var(--spacing-12);
  margin-right: var(--spacing-12);
}
.m-16 {
  margin: var(--spacing-16);
}
.mt-16 {
  margin-top: var(--spacing-16);
}
.ml-16 {
  margin-left: var(--spacing-16);
}
.mb-16 {
  margin-bottom: var(--spacing-16);
}
.mr-16 {
  margin-right: var(--spacing-16);
}
.my-16 {
  margin-top: var(--spacing-16);
  margin-bottom: var(--spacing-16);
}
.mx-16 {
  margin-left: var(--spacing-16);
  margin-right: var(--spacing-16);
}
.m-20 {
  margin: var(--spacing-20);
}
.mt-20 {
  margin-top: var(--spacing-20);
}
.ml-20 {
  margin-left: var(--spacing-20);
}
.mb-20 {
  margin-bottom: var(--spacing-20);
}
.mr-20 {
  margin-right: var(--spacing-20);
}
.my-20 {
  margin-top: var(--spacing-20);
  margin-bottom: var(--spacing-20);
}
.mx-20 {
  margin-left: var(--spacing-20);
  margin-right: var(--spacing-20);
}
.m-24 {
  margin: var(--spacing-24);
}
.mt-24 {
  margin-top: var(--spacing-24);
}
.ml-24 {
  margin-left: var(--spacing-24);
}
.mb-24 {
  margin-bottom: var(--spacing-24);
}
.mr-24 {
  margin-right: var(--spacing-24);
}
.my-24 {
  margin-top: var(--spacing-24);
  margin-bottom: var(--spacing-24);
}
.mx-24 {
  margin-left: var(--spacing-24);
  margin-right: var(--spacing-24);
}
.m-32 {
  margin: var(--spacing-32);
}
.mt-32 {
  margin-top: var(--spacing-32);
}
.ml-32 {
  margin-left: var(--spacing-32);
}
.mb-32 {
  margin-bottom: var(--spacing-32);
}
.mr-32 {
  margin-right: var(--spacing-32);
}
.my-32 {
  margin-top: var(--spacing-32);
  margin-bottom: var(--spacing-32);
}
.mx-32 {
  margin-left: var(--spacing-32);
  margin-right: var(--spacing-32);
}
.m-auto {
  margin: auto;
}
/*End Margin*/
/*Padding*/
.p-8 {
  padding: var(--spacing-8);
}
.pt-8 {
  padding-top: var(--spacing-8);
}
.pl-8 {
  padding-left: var(--spacing-8);
}
.pb-8 {
  padding-bottom: var(--spacing-8);
}
.pr-8 {
  padding-right: var(--spacing-8);
}
.py-8 {
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-8);
}
.px-8 {
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
}
.p-12 {
  padding: var(--spacing-12);
}
.pt-12 {
  padding-top: var(--spacing-12);
}
.pl-12 {
  padding-left: var(--spacing-12);
}
.pb-12 {
  padding-bottom: var(--spacing-12);
}
.py-12 {
  padding-top: var(--spacing-12);
  padding-bottom: var(--spacing-12);
}
.px-12 {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}
.p-16 {
  padding: var(--spacing-16);
}
.pt-16 {
  padding-top: var(--spacing-16);
}
.pl-16 {
  padding-left: var(--spacing-16);
}
.pb-16 {
  padding-bottom: var(--spacing-16);
}
.pr-16 {
  padding-right: var(--spacing-16);
}
.py-16 {
  padding-top: var(--spacing-16);
  padding-bottom: var(--spacing-16);
}
.px-16 {
  padding-left: var(--spacing-16);
  padding-right: var(--spacing-16);
}
.p-20 {
  padding: var(--spacing-20);
}
.pt-20 {
  padding-top: var(--spacing-20);
}
.pl-20 {
  padding-left: var(--spacing-20);
}
.pb-20 {
  padding-bottom: var(--spacing-20);
}
.pr-20 {
  padding-right: var(--spacing-20);
}
.pr-12 {
  padding-right: var(--spacing-12);
}
.py-20 {
  padding-top: var(--spacing-20);
  padding-bottom: var(--spacing-20);
}
.px-20 {
  padding-left: var(--spacing-20);
  padding-right: var(--spacing-20);
}
.p-24 {
  padding: var(--spacing-24);
}
.pt-24 {
  padding-top: var(--spacing-24);
}
.pl-24 {
  padding-left: var(--spacing-24);
}
.pb-24 {
  padding-bottom: var(--spacing-24);
}
.pr-24 {
  padding-right: var(--spacing-24);
}
.py-24 {
  padding-top: var(--spacing-24);
  padding-bottom: var(--spacing-24);
}
.px-24 {
  padding-left: var(--spacing-24);
  padding-right: var(--spacing-24);
}
.p-32 {
  padding: var(--spacing-32);
}
.pt-32 {
  padding-top: var(--spacing-32);
}
.pl-32 {
  padding-left: var(--spacing-32);
}
.pb-32 {
  padding-bottom: var(--spacing-32);
}
.pr-32 {
  padding-right: var(--spacing-32);
}
.py-32 {
  padding-top: var(--spacing-32);
  padding-bottom: var(--spacing-32);
}
.px-32 {
  padding-left: var(--spacing-32);
  padding-right: var(--spacing-32);
}
/*End Padding*/

.min-vh-100 {
  min-height: 100vh;
}

@media (min-width: 796px) {
  .gap-32 {
    gap: var(--spacing-32);
  }
}

@media (min-width: 576px) {
  /*Margin*/
  .m-md-0 {
    margin: 0px;
  }
  .mt-md-0 {
    margin-top: 0px;
  }
  .ml-md-0 {
    margin-left: 0px;
  }
  .mb-md-0 {
    margin-bottom: 0px;
  }
  .mr-md-0 {
    margin-right: 0px;
  }
  .my-md-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mx-md-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .m-md-8 {
    margin: var(--spacing-8);
  }
  .mt-md-8 {
    margin-top: var(--spacing-8);
  }
  .ml-md-8 {
    margin-left: var(--spacing-8);
  }
  .mb-md-8 {
    margin-bottom: var(--spacing-8);
  }
  .mr-md-8 {
    margin-right: var(--spacing-8);
  }
  .my-md-8 {
    margin-top: var(--spacing-8);
    margin-bottom: var(--spacing-8);
  }
  .mx-md-8 {
    margin-left: var(--spacing-8);
    margin-right: var(--spacing-8);
  }
  .m-md-12 {
    margin: var(--spacing-12);
  }
  .mt-md-12 {
    margin-top: var(--spacing-12);
  }
  .ml-md-12 {
    margin-left: var(--spacing-12);
  }
  .mb-md-12 {
    margin-bottom: var(--spacing-12);
  }
  .mr-md-12 {
    margin-right: var(--spacing-12);
  }
  .my-md-12 {
    margin-top: var(--spacing-12);
    margin-bottom: var(--spacing-12);
  }
  .mx-md-12 {
    margin-left: var(--spacing-12);
    margin-right: var(--spacing-12);
  }
  .m-md-16 {
    margin: var(--spacing-16);
  }
  .mt-md-16 {
    margin-top: var(--spacing-16);
  }
  .ml-md-16 {
    margin-left: var(--spacing-16);
  }
  .mb-md-16 {
    margin-bottom: var(--spacing-16);
  }
  .mr-md-16 {
    margin-right: var(--spacing-16);
  }
  .my-md-16 {
    margin-top: var(--spacing-16);
    margin-bottom: var(--spacing-16);
  }
  .mx-md-16 {
    margin-left: var(--spacing-16);
    margin-right: var(--spacing-16);
  }
  .m-md-20 {
    margin: var(--spacing-20);
  }
  .mt-md-20 {
    margin-top: var(--spacing-20);
  }
  .mt-md-40 {
    margin-top: var(--spacing-40);
  }
  .ml-md-20 {
    margin-left: var(--spacing-20);
  }
  .mb-md-20 {
    margin-bottom: var(--spacing-20);
  }
  .mr-md-20 {
    margin-right: var(--spacing-20);
  }
  .my-md-20 {
    margin-top: var(--spacing-20);
    margin-bottom: var(--spacing-20);
  }
  .mx-md-20 {
    margin-left: var(--spacing-20);
    margin-right: var(--spacing-20);
  }
  .m-md-24 {
    margin: var(--spacing-24);
  }
  .mt-md-24 {
    margin-top: var(--spacing-24);
  }
  .ml-md-24 {
    margin-left: var(--spacing-24);
  }
  .mb-md-24 {
    margin-bottom: var(--spacing-24);
  }
  .mr-md-24 {
    margin-right: var(--spacing-24);
  }
  .my-md-24 {
    margin-top: var(--spacing-24);
    margin-bottom: var(--spacing-24);
  }
  .mx-md-24 {
    margin-left: var(--spacing-24);
    margin-right: var(--spacing-24);
  }
  .m-md-32 {
    margin: var(--spacing-32);
  }
  .mt-md-32 {
    margin-top: var(--spacing-32);
  }
  .ml-md-32 {
    margin-left: var(--spacing-32);
  }
  .mb-md-32 {
    margin-bottom: var(--spacing-32);
  }
  .mr-md-32 {
    margin-right: var(--spacing-32);
  }
  .my-md-32 {
    margin-top: var(--spacing-32);
    margin-bottom: var(--spacing-32);
  }
  .mx-md-32 {
    margin-left: var(--spacing-32);
    margin-right: var(--spacing-32);
  }
  /*End Margin*/
  /*Padding*/
  .p-md-0 {
    padding: 0px;
  }
  .pt-md-0 {
    padding-top: 0px;
  }
  .pl-md-0 {
    padding-left: 0px;
  }
  .pb-md-0 {
    padding-bottom: 0px;
  }
  .pr-md-0 {
    padding-right: 0px;
  }
  .py-md-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .px-md-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .p-md-8 {
    padding: var(--spacing-8);
  }
  .pt-md-8 {
    padding-top: var(--spacing-8);
  }
  .pl-md-8 {
    padding-left: var(--spacing-8);
  }
  .pb-md-8 {
    padding-bottom: var(--spacing-8);
  }
  .pr-md-8 {
    padding-right: var(--spacing-8);
  }
  .py-md-8 {
    padding-top: var(--spacing-8);
    padding-bottom: var(--spacing-8);
  }
  .px-md-8 {
    padding-left: var(--spacing-8);
    padding-right: var(--spacing-8);
  }
  .p-md12 {
    padding: var(--spacing-12);
  }
  .pt-md-12 {
    padding-top: var(--spacing-12);
  }
  .pl-md-12 {
    padding-left: var(--spacing-12);
  }
  .pb-md-12 {
    padding-bottom: var(--spacing-12);
  }
  .pr-md-12 {
    padding-right: var(--spacing-12);
  }
  .py-md-12 {
    padding-top: var(--spacing-12);
    padding-bottom: var(--spacing-12);
  }
  .px-md-12 {
    padding-left: var(--spacing-12);
    padding-right: var(--spacing-12);
  }
  .p-md-16 {
    padding: var(--spacing-16);
  }
  .pt-md-16 {
    padding-top: var(--spacing-16);
  }
  .pl-md-16 {
    padding-left: var(--spacing-16);
  }
  .pb-md-16 {
    padding-bottom: var(--spacing-16);
  }
  .pr-md-16 {
    padding-right: var(--spacing-16);
  }
  .py-md-16 {
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
  }
  .px-md-16 {
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
  }
  .p-md-20 {
    padding: var(--spacing-20);
  }
  .pt-md-20 {
    padding-top: var(--spacing-20);
  }
  .pl-md-20 {
    padding-left: var(--spacing-20);
  }
  .pb-md-20 {
    padding-bottom: var(--spacing-20);
  }
  .pr-md-20 {
    padding-right: var(--spacing-20);
  }
  .py-md-20 {
    padding-top: var(--spacing-20);
    padding-bottom: var(--spacing-20);
  }
  .px-md-20 {
    padding-left: var(--spacing-20);
    padding-right: var(--spacing-20);
  }
  .p-md-24 {
    padding: var(--spacing-24);
  }
  .pt-md-24 {
    padding-top: var(--spacing-24);
  }
  .pl-md-24 {
    padding-left: var(--spacing-24);
  }
  .pb-md-24 {
    padding-bottom: var(--spacing-24);
  }
  .pr-md-24 {
    padding-right: var(--spacing-24);
  }
  .py-md-24 {
    padding-top: var(--spacing-24);
    padding-bottom: var(--spacing-24);
  }
  .px-md-24 {
    padding-left: var(--spacing-24);
    padding-right: var(--spacing-24);
  }
  .p-md-32 {
    padding: var(--spacing-32);
  }
  .pt-md-32 {
    padding-top: var(--spacing-32);
  }
  .pl-md-32 {
    padding-left: var(--spacing-32);
  }
  .pb-md-32 {
    padding-bottom: var(--spacing-32);
  }
  .pr-md-32 {
    padding-right: var(--spacing-32);
  }
  .py-md-32 {
    padding-top: var(--spacing-32);
    padding-bottom: var(--spacing-32);
  }
  .px-md-32 {
    padding-left: var(--spacing-32);
    padding-right: var(--spacing-32);
  }
  /*End Padding*/
  .border-md {
    border-radius: var(--card-border-radius);
    border: var(--card-border);
  }
  .border-b-none-md {
    border-bottom: 0px !important;
  }
  .w-md-100 {
    width: 100px !important;
  }
}

.border-t {
  border-top: var(--card-border);
}
.border-b {
  border-bottom: var(--card-border);
}

.border {
  border-radius: var(--card-border-radius);
  border: var(--card-border);
}

.border-brand {
  border: 2px solid var(--brand-01);
}
.brand-shadow {
  box-shadow: 0px 0px 5px var(--brand-01);
}

.bottom-shadow {
  box-shadow: 0px 10px 14px var(--gray-10);
}

.radius {
  border-radius: var(--card-border-radius);
}

.max-w-1100 {
  max-width: 1100px;
}
.max-w-850 {
  max-width: 850px;
}
.max-w-150 {
  max-width: 150px;
}
.max-w-100 {
  max-width: 100px;
}
.max-h-45 {
  max-height: 45px;
}
.max-h-65 {
  max-height: 65px;
}
.w-85 {
  width: 85px;
}
.w-270 {
  width: 270px;
}

.min-w-100 {
  min-width: 100px;
}

.w-100p {
  width: 100%;
}

.bg-primary {
  background-color: var(--brand-01);
  box-shadow: var(--bg-primary-box-shadow);
}

.bg-white {
  background-color: var(--white);
}

.bg-grey-05 {
  background-color: var(--gray-05);
}

.bg-grey-10 {
  background-color: var(--gray-10);
}

.bg-grey-20 {
  background-color: var(--gray-20);
}

.divider-white {
  width: 100%;
  height: 1px;
  background-color: var(--white);
}
.divider-grey {
  width: 100%;
  height: 1px;
  background-color: var(--border-color);
}
.h-fit-content {
  height: fit-content;
}

.hidden-sm {
  display: none;
}

@media (min-width: 576px) {
  .hidden-md {
    display: none;
  }

  .hidden-sm {
    display: unset !important;
  }
}
