[data-theme='toyota'] {
  --brand-01-lightest: var(--gray-10);
  --brand-01-light: #cb0418;
  --brand-01: #e50c22;
  /* RGB for the brand-01 color */
  --brand-01-rgb: 229, 12, 34;
  --brand-01-shade: #cb0418;
  --brand-01-darkest: #b90315;
  --brand-02: #ffffff;
  --brand-03: #0076dc;
  --brand-03-light: #048bff;
  --brand-04: #09a767;
  --brand-05: #f2f3f8;
  --brand-06: rgb(0, 162, 238);
  --brand-07: #75cdff;

  --gray-60: #58595b;
  --gray-20: #dde0e6;
  --gray-05: #f9f9f9;

  /* Fonts */
  --font-family__primary: 'Toyota Type', sans-serif;
  --font-family__secondary: 'Toyota Type', sans-serif;
  --font-family__mono: 'Toyota Type', sans-serif;
  --font-family__headline-color: var(--black);

  /* Button */
  --button-link-color: var(--gray-50);

  /* Select input */
  --select-input-bg-image: url("data:image/svg+xml;utf8,<svg width='21' height='12' viewBox='0 0 21 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.733379 0.733255C0.0400456 1.42659 0.0400456 2.54659 0.733379 3.23992L8.89338 11.3999C9.58671 12.0933 10.7067 12.0933 11.4 11.3999L19.56 3.23992C20.2534 2.54659 20.2534 1.42659 19.56 0.733255C18.8667 0.0399218 17.7467 0.0399218 17.0534 0.733255L10.1378 7.63103L3.24005 0.733255C2.54671 0.0399218 1.40893 0.0576992 0.733379 0.733255Z' fill='%2358595B'/></svg>");
  --select-background-position: right 15px;
  --select-input__label-color: var(--gray-60);
  --select-input__label-font-size: var(--font-size-16);

  /* radio */
  --radio__icon: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Ccircle cx='13' cy='13' r='12' fill='%23000000'/%3E%3Ccircle cx='13' cy='13' r='12.5' stroke='%23000000'/%3E%3C/g%3E%3Ccircle cx='13' cy='13' r='4' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='26' height='26' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E")
    center center no-repeat;
  --radio__background-color--active: var(--gray-10);
  --radio__border--active: 2px solid var(--brand-01-shade);
  --radio__box-shadow--active: none;
  --radio__color--active: var(--black);
  --radio__box-shadow--focus: var(--radio__border--active);
  --radio__border--focus: var(--radio__border--active);

  /* checkbox */
  --check-icon-bg: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='25' height='25' rx='6.5' fill='%23000000' stroke='%234024D3'/%3E%3Cpath d='M17.1611 8.80664L10.847 14.9709L8.82819 13C8.4094 12.5911 7.73289 12.5911 7.31409 13C6.8953 13.4089 6.8953 14.0693 7.31409 14.4782L10.0953 17.1934C10.5141 17.6022 11.1906 17.6022 11.6094 17.1934L18.6859 10.2848C19.1047 9.87595 19.1047 9.21549 18.6859 8.80664C18.2671 8.39779 17.5799 8.39779 17.1611 8.80664Z' fill='white'/%3E%3C/svg%3E%0A")
    center center no-repeat;
  --checkbox-button__labelContainer-bg--checked: var(--white);
  --checkbox-button__labelContainer-box-shadow--checked: none;
  --checkbox-button__labelContainer-border--checked: 1px solid
    var(--brand-01-shade);
  --checkbox-button__description-color: var(--gray-60);
  --checkbox-button__description-color--checked: var(--gray-60);
  --checkbox-button__label-color: var(--black);
  --checkbox-button__label-input-box-shadow--focus: 0px 2px 0px
    rgba(221, 224, 230, 0.9);
  --checkbox-button__box-after-bg--focus: var(--black);
  --checkbox-button__box-after-border-radius--focus: 4px;
  --checkbox-button__box-border: 1px solid #878d96;
  --checkbox-button__labelContainer-border: 1px solid var(--gray-20);

  /* inputs */
  --input-border: 1px solid var(--gray-20);
  --input-border-focus: var(--input-border);
  --input-box-shadow: 0;
  --input-height: 56px;
  --input__label-color: var(--gray-60);
  --input__label-font-size: var(--font-size-16);
  --border-radius: 6px;
  --step-header__progress: var(--brand-03);

  /* Step Header */
  --step-header__logo-max-width: 150px;
  --edit__header-bg: #58595b;

  --toggle__input--checked__label__span: var(--brand-01);
  --blue-page__badge-bg: var(--brand-03);
  --progress-bar__indicator-bg: var(--brand-03);

  /* Driver/Vehicle Entities */
  --entity-summary-margin: 0 0 15px 0;
}

/* Custom Overrides */

[data-theme='toyota'] .cc-partner-logo {
  max-height: 35px;
}

[data-theme='toyota'] .cc-toggle__label {
  background: none;
}

[data-theme='toyota'] .cc-toggle__label span {
  background-color: var(--white);
  border: 1px solid var(--gray-20);
  border-radius: 5px;
}

[data-theme='toyota'] .cc-toggle__label:hover span {
  border: 1px solid var(--gray-20);
  background-color: var(--gray-10);
}

[data-theme='toyota'] .cc-toggle__label:hover {
  background: none;
}

[data-theme='toyota'] div.cc-toggle input:checked + .cc-toggle__label > span {
  border: 1px solid var(--brand-01);
  background-color: var(--brand-01);
  color: var(--white);
}

[data-theme='toyota'] div.cc-toggle input:checked + .cc-toggle__label {
  background: none;
}

[data-theme='toyota'] div.cc-toggle input:focus + .cc-toggle__label > span {
  background-color: var(--brand-01);
}

[data-theme='toyota']
  div.cc-toggle
  input:focus:not(:focus-visible)
  + .cc-toggle__label
  > span,
div.cc-toggle input:active:not(:focus-visible) + .cc-toggle__label > span {
  border: 2px solid var(--brand-01);
  background-color: var(--brand-01);
  color: var(--white);
}

[data-theme='toyota'] button.cc-button__contained {
  background: var(--brand-01);
}

[data-theme='toyota'] button.cc-button__contained,
[data-theme='toyota'] button.cc-button__contained div,
[data-theme='toyota'] button.cc-button__contained span {
  border-radius: 50px;
}

[data-theme='toyota'] button.cc-button__contained:disabled div {
  background: #f5cdd1;
  border: none;
  color: var(--white);
  box-shadow: none;
  transform: translateY(0px);
}

[data-theme='toyota'] button.cc-button__contained:disabled {
  box-shadow: none;
}
[data-theme='toyota']
  button.cc-button__contained:disabled
  div
  > div
  > div:before {
  border-top-color: var(--black) !important;
}

[data-theme='toyota'] button.cc-button__contained:active,
[data-theme='toyota'] button.cc-button__contained:active div {
  background: var(--brand-01-darkest);
}

[data-theme='toyota'] button.cc-edit__button {
  box-shadow: none;
}

[data-theme='toyota'] button.cc-edit__button div {
  background: var(--brand-01);
  border: 0;
  color: var(--white);
  padding: 10px 27px;
}

[data-theme='toyota'] button.cc-edit__button:hover div {
  background: var(--brand-01-shade);
  border: 0;
}

[data-theme='toyota'] button.cc-edit__close-button svg {
  fill: var(--white);
}

[data-theme='toyota'] button.cc-step-header__toggle rect {
  fill: var(--white);
}

[data-theme='toyota'] button.cc-button__outlined,
[data-theme='toyota'] button.cc-button__outlined div {
  background: none;
  border: none;
  border-radius: 50px;
}

[data-theme='toyota'] button.cc-button__outlined {
  color: var(--black);
  box-shadow: 0px 0px 0px 1px var(--black);
}

[data-theme='toyota'] button.cc-button__outlined div {
  color: var(--black);
}

[data-theme='toyota'] button.cc-button__outlined:not([disabled]):hover {
  box-shadow: 0px 0px 0px 2px var(--black);
}

[data-theme='toyota'] button.cc-button__outlined:focus,
[data-theme='toyota'] button.cc-button__outlined:focus div {
  background: none;
  border: none;
}

[data-theme='toyota'] button.cc-button__outlined:not([disabled]):focus {
  box-shadow: 0px 0px 0px 2px var(--black);
}

[data-theme='toyota'] button.cc-button__outlined:not([disabled]):focus div {
  box-shadow: none;
}

[data-theme='toyota'] button.cc-button__outlined:not([disabled]):active div {
  background: var(--black);
  color: var(--white);
}

[data-theme='toyota']
  button.cc-button__outlined:not([disabled]):active
  div
  svg
  path {
  fill: var(--white);
}

[data-theme='toyota'] button.cc-button__danger {
  box-shadow: 0px 0px 0px 1px var(--alert-10);
}

[data-theme='toyota'] button.cc-button__danger div {
  color: var(--alert-10);
}

[data-theme='toyota'] button.cc-button__danger:not([disabled]):hover {
  box-shadow: 0px 0px 0px 2px var(--alert-10);
}

[data-theme='toyota'] button.cc-button__danger:not([disabled]):focus {
  box-shadow: 0px 0px 0px 2px var(--alert-10);
}

[data-theme='toyota'] button.cc-button__danger:not([disabled]):active div {
  background: var(--alert-10);
  color: var(--white);
}

[data-theme='toyota'] button.cc-button__link:hover {
  color: var(--brand-01-shade);
}
[data-theme='toyota'] button.cc-button__link:active {
  color: var(--brand-01-darkest);
}

[data-theme='toyota'] .consentDisclosureContainer {
  margin-top: 0px;
  border-top: none;
  padding-top: var(--spacing-8);
}
