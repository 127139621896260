.typeahead {
  position: relative;
}

.results {
  margin-bottom: 24px;
  margin-top: 3px;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.results ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.results button {
  background: none;
  border: none;
  border-bottom: 2px solid #f3f6f9;
  display: block;
  color: black;
  font-family: var(--font-family__primary);
  font-size: 12px;
  overflow: hidden;
  padding: 12px 8px;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.results li:first-child button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.results li:last-child button {
  border: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.results li:last-child button span {
  font-size: 18px;
  line-height: 0;
  margin-right: 5px;
}

.results button:hover,
.active button {
  background: var(--typeahead-bg--hover);
  color: var(--button-link-color);
}

.results .matched {
  font-weight: 500;
}
