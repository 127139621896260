@value medium from '~styles/breakpoints.module.css';

.mainModalTitle {
  composes: color-headline from global;
  margin-bottom: 8px;
  text-align: left;
  font-family: var(--font-family__secondary);
  font-weight: 500;
  font-size: var(--font-size-18);
  line-height: var(--line-height-18);
}

.mainModalText {
  color: var(--gray-60);
  font-size: var(--font-size-13);
  line-height: var(--line-height-13);
  margin: 0;
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  text-align: right;
}

.controls button {
  margin-left: 12px;
}

@media medium {
  .mainModalText {
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
  }
}
