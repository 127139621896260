.buttonContainer {
  margin-bottom: 16px;
  border-radius: 3px;
  height: 56px;
  width: 100%;
  padding: 0 16px;
  background: var(--step-header__item-bg);
  border: var(--step-header__item-border);
  box-shadow: var(--step-header__item-box-shadow);
}

.editItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editItem:last-child {
  margin-bottom: 0;
}

.buttonContainer:disabled {
  opacity: 0.5;
}

.editItemHeading {
  font-size: var(--font-size-14);
  line-height: var(--line-height-14);
  color: var(--text-on-background);
  font-family: var(--font-family__primary);
  font-weight: 400;
  text-align: left;
}
