@value medium from '~styles/breakpoints.module.css';
@value bottom-fixed-button from '~styles/zindices.module.css';

.ctaMobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: bottom-fixed-button;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background: var(--confirm-quote__cta-mobile-bg);

  border-radius: 0;
}

.default {
  box-shadow: var(--confirm-quote__cta-mobile-box-shadow);
  padding: 8px 5% 12px 5%;
}

.splash {
  box-shadow: none;
  padding: 0 24px 30px;
}

@media medium {
  .ctaMobile {
    display: none;
  }
}
