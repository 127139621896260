.loading {
  background: var(--background);
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  top: 0;
}

.spinner {
  width: 80px;
  height: 80px;

  position: relative;
  margin: 100px auto;
}

.bounceOne,
.bounceTwo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--pulse-loader__bg);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  animation: sk-bounce 2s infinite ease-in-out;
}

.bounceTwo {
  animation-delay: -1s;
}

.loadingText {
  width: auto;
  font-family: var(--font-family__primary);
  font-size: var(--font-size-18);
  color: var(--font-family__color);
  position: absolute;
  text-align: center;
  padding-top: 150px;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
