@value medium from '~styles/breakpoints.module.css';
@value loadingBar from '~styles/animations.module.css';

.container {
  position: relative;
  padding: 56px 16px 16px;
}

.title {
  font-size: 16px;
  text-align: center;
  margin: 16px 0 8px;
}

@media medium {
  .title {
    margin-top: 0;
  }
}

.field {
  border-bottom: 1px solid #dee2e6;
  width: 100%;
}

.displayField {
  padding-top: 8px;
  display: flex;
  align-items: center;
}

.fieldContainer {
  flex: 10;
  min-width: 0;
}

.editIcon {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.display {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.3px;
  color: #6b7480;
  text-transform: uppercase;
  text-align: left;
}

.value {
  font-size: 14px;
  text-align: left;
  padding: 4px 0 8px 0;
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.commands {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

@media medium {
  .container {
    padding: 80px 32px 32px;
    display: flex;
    justify-content: center;
  }

  .content {
    width: 824px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .header {
    font-size: 16px;
  }

  .fields {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .field {
    width: 350px;
    margin: 0 8px;
  }
}

.labelLoader {
  composes: loadingBar;
  height: 10px;
  width: 50px;
}

.descriptionLoader {
  composes: loadingBar;
  height: 14px;
}
