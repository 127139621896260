@import './styles/common/flex.css';
@import './styles/common/spacing.css';
@import './styles/themes/current.css';
@import './styles/themes/bankie.css';
@import './styles/themes/goodcover.css';
@import './styles/themes/toyota.css';
@import './styles/typography.css';
@import './styles/animations.module.css';
@import './styles/svg-icons.css';

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family__primary);
  font-size: 16px;
  letter-spacing: 0.3px;
  margin: 0;
  padding: 0;
  font-display: swap;
}

body {
  background: var(--background);
  width: 100%;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.grecaptcha-badge {
  visibility: hidden;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
  font-family: var(--font-family__primary);
}

button:disabled {
  cursor: not-allowed;
}

button:focus {
  outline-offset: 4px;
  outline-color: #b3b6f9;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

p {
  font-size: var(--font-size-16);
  color: var(--font-family__color);
  line-height: 22px;
  margin: 0 0 16px;
}

/* remove FireFox outline on selects */
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

@media medium {
  p {
    font-size: var(--font-size-16);
  }
}

a {
  color: var(--brand-01);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  font-weight: normal;
}

br {
  content: '';
  display: block;
  margin: 0;
}

.toggle {
  bottom: 10px;
  left: 10px;
  position: fixed;
  z-index: 999;
}

.text-center {
  text-align: center;
}

div[data-testid='apply-confirm_quote'] .chat-bubble {
  bottom: 100px;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
