.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-around {
  justify-content: space-around;
}
.justify-end {
  justify-content: end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-end {
  align-items: flex-end;
}
.align-stretch {
  align-items: stretch;
}
.gap-8 {
  gap: 8px;
}
.gap-32 {
  gap: 32px;
}

.align-self-center {
  align-self: center;
}
.gap-12 {
  gap: 12px;
}
.gap-16 {
  gap: 16px;
}
.flex-1 {
  flex: 1;
}
.flex-1-0-50 {
  flex: 1 0 50%;
}
.flex-4 {
  flex: 4;
}
.flex-3 {
  flex: 3;
}
.flex-shrink-1 {
  flex-shrink: 1;
}

@media (min-width: 576px) {
  .flex-row-sm {
    display: flex;
    flex-direction: row;
  }
  .flex-column-sm {
    display: flex;
    flex-direction: column;
  }
  .justify-between-sm {
    justify-content: space-between;
  }
  .justify-center-md {
    justify-content: center;
  }
  .align-center-md {
    align-items: center;
  }
}

@media (min-width: 768px) {
  .flex-row-md {
    display: flex;
    flex-direction: row;
  }
  .flex-column-md {
    display: flex;
    flex-direction: column;
  }
  .justify-between-md {
    justify-content: space-between;
  }
  .justify-center-md {
    justify-content: center;
  }
  .align-center-md {
    align-items: center;
  }
  .flex-1-md {
    flex: 1;
  }
  .flex-2-md {
    flex: 2;
  }
}

@media (min-width: 992px) {
  .flex-row-lg {
    display: flex;
    flex-direction: row;
  }
  .flex-column-lg {
    display: flex;
    flex-direction: column;
  }
  .justify-between-lg {
    justify-content: space-between;
  }
  .justify-center-lg {
    justify-content: center;
  }
  .align-center-lg {
    align-items: center;
  }
}
