@value medium from '~styles/breakpoints.module.css';

.main {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 80px 16px 0;
  width: 100%;
  margin-bottom: 25px;
}

@media medium {
  .main {
    padding: 136px 32px 0;
  }
}
