@value medium, iphone5 from '~styles/breakpoints.module.css';
@value medium, iphone5 from '~styles/breakpoints.module.css';

.item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.title {
  composes: headline5 text-center from global;
  font-size: var(--font-size-24);
  line-height: var(--line-height-24);
}

.customInfoModal {
  color: var(--brand-01);
}

.icon {
  margin-left: 8px;
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customIcon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

@media iphone5 {
  .title {
    font-size: 20px;
    line-height: 24px;
  }
}

@media medium {
  .title {
    font-size: var(--font-size-32);
    line-height: var(--line-height-32);
    max-width: 700px;
  }
}

.subTitle {
  composes: body1 color-gray-60 from global;
  font-size: var(--font-size-16);
  line-height: var(--line-height-16);
  margin-top: 12px;
  text-align: center;
  margin-bottom: 8px;

  /*Note this allows us to use backticks to include line breaks (look in NotFound.tsx)*/
  white-space: pre-line;
}

@media iphone5 {
  .subTitle {
    font-size: 14px;
    line-height: 18px;
  }
}

@media medium {
  .subTitle {
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
    max-width: 700px;
  }
}
