@value medium from '~styles/breakpoints.module.css';

.main {
  -webkit-overflow-scrolling: touch;
  align-items: center;
  background: var(--splash-page-assets__main-bg);
  color: var(--splash-page-assets__main-color);
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.flat {
  background: var(--splash-page-assets__flat-bg);
  min-height: 100vh;
}

.logo {
  margin: 24px auto;
  width: 120px;
}

@media medium {
  .logo {
    margin: 50px auto 80px;
    width: 200px;
  }
}

.title {
  composes: global-title from global;
  color: var(--splash-page-assets__main-color);
  font-size: var(--font-size-32);
  line-height: var(--line-height-32);

  width: 100%;
  max-width: 420px;
  text-align: center;
  margin-bottom: 16px;
}

.text {
  composes: body1 from global;
  color: var(--splash-page-assets__main-color);
  font-size: var(--font-size-16);
  line-height: var(--line-height-16);
  width: 100%;
  max-width: 420px;
  text-align: center;
}
