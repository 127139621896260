@value medium from '~styles/breakpoints.module.css';

.content {
  margin: 16px auto 32px;
  width: 100%;
}

@media medium {
  .content {
    margin: 24px auto 32px;
    max-width: 320px;
  }
}
