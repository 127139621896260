@value medium from '~styles/breakpoints.module.css';

.documentViewer {
  position: relative;
  padding-top: 100px;
  width: 100%;
  z-index: 10000;
  background: #ffffff;
  height: 100%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  flex-wrap: wrap;
  width: 100%;
}
.headerInner {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: var(--document-signatures__inner-header-align-items);
  padding: 15px 0;
  border-bottom: 1px solid var(--gray-20);
}

.headingText {
  composes: global-title text-center from global;
  max-width: 500px;
  font-weight: 700;
  font-size: var(--font-size-16);
  display: none;
}

.progressBar {
  height: 5px;
  background: var(--document-signatures__progress-bar-bg);
  box-shadow: var(--document-signatures__progress-bar-box-shadow);
}

.progressWrapper {
  height: 5px;
  width: 100%;
  overflow: hidden;
}

.documentContentOuter {
  padding: 60px 0 0;
  background: #ffffff;
}

.documentContentInner {
  width: 85%;
  max-width: 1200px;
  margin: auto;
}

.documentContentInner h1 {
  font-size: 24px;
  font-weight: 600;
}

.documentContentInner h1,
.documentContentInner h2,
.documentContentInner h3,
.documentContentInner h4,
.documentContentInner h5 {
  color: var(--font-family__headline-color);
}

.documentContentInner p {
  color: var(--gray-60);
  font-size: var(--font-size-14);
  line-height: var(--line-height-14);
}

.buttonContainer {
  width: 100%;
  text-align: center;
  margin: auto;
  padding: 30px 0;
}

.button {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 14px;
  text-transform: none;
  color: #313541;
  background: #ffffff;
}

.close {
  display: none;
  color: var(--document-signatures__close-button-color);
  font-weight: 700;
  font-size: var(--font-size-14);
  line-height: var(--line-height-14);
}

.button .icon {
  margin-right: 8px;
}

@media medium {
  .button .icon {
    margin-right: 25px;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  margin-right: 25px;
  color: var(--font-family__headline-color);
}

.printIcon {
  background: #ffffff;
  border: 1px solid #eff1f2;
  border-radius: 50%;
}

.printButton {
  position: fixed;
  bottom: 20px;
  right: 0;
  background: none;
  padding: 0;
}

.logo {
  width: var(--document-signatures__logo-width);
  margin-left: 25px;
}

.formActionsPrint {
  text-align: left;
  display: flex;
  align-items: center;
}

.print {
  display: none;
}

.headingTextMobile {
  display: block;
  width: 100%;
  text-align: center;
  padding: 15px 10px;
  border-bottom: solid 1px #eff1f2;
  font-weight: bold;
  color: var(--font-family__headline-color);
  font-size: var(--font-size-14);
  line-height: var(--line-height-14);
  background: #ffffff;
}

.spinnerClose {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  margin-right: 25px;
  border: 1px solid #eff1f2;
  border-radius: 50%;
  margin-left: 8px;
}

.isSubmitting .close {
  opacity: 0.3;
}

@media print {
  .header {
    display: none;
  }
  .buttonContainer {
    display: none;
  }
  .formActionsPrint {
    display: none;
  }
  .documentContentOuter {
    display: none;
  }

  .print {
    background: #ffffff;
    display: block;
    height: 100%;
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 99999;
  }
}

@media medium {
  .button {
    left: auto;
    right: 32px;
    top: 32px;
  }

  .close {
    display: block;
  }

  .icon {
    border: 1px solid #eff1f2;
    border-radius: 50%;
    margin-left: 8px;
  }

  .documentContentOuter {
    padding: 30px 0;
    background: #ffffff;
  }

  .headingText {
    display: block;
  }

  .headerInner {
    padding: 25px 0;
  }

  .headingTextMobile {
    display: none;
  }
}
