[data-theme='bankie'] {
  --brand-01-lightest: #ecf9f1;
  --brand-01-light: #b3e8c9;
  --brand-01: #1ec677;
  /* RGB for the brand-01 color */
  --brand-01-rgb: 30, 198, 119;
  --brand-01-shade: #109959;
  --brand-02: #ffffff;
  --brand-03-light: #edffc2;
  --brand-03: #d9ff80;
  --brand-04: #21262a;
  --brand-05: #f2f3f8;
  --brand-06: #ffab58;
  --brand-07: #75cdff;

  /* Select input */
  --select-input-bg-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2830, 198, 119%29'></polygon></svg>");

  /* radio */
  --radio__icon: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Ccircle cx='13' cy='13' r='12' fill='%231ec677'/%3E%3Ccircle cx='13' cy='13' r='12.5' stroke='%231ec677'/%3E%3C/g%3E%3Ccircle cx='13' cy='13' r='4' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='26' height='26' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E")
    center center no-repeat;

  /* checkbox */
  --check-icon-bg: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='25' height='25' rx='6.5' fill='%231ec677' stroke='%234024D3'/%3E%3Cpath d='M17.1611 8.80664L10.847 14.9709L8.82819 13C8.4094 12.5911 7.73289 12.5911 7.31409 13C6.8953 13.4089 6.8953 14.0693 7.31409 14.4782L10.0953 17.1934C10.5141 17.6022 11.1906 17.6022 11.6094 17.1934L18.6859 10.2848C19.1047 9.87595 19.1047 9.21549 18.6859 8.80664C18.2671 8.39779 17.5799 8.39779 17.1611 8.80664Z' fill='white'/%3E%3C/svg%3E%0A")
    center center no-repeat;
}
