@import '~styles/typography.css';
:root {
  --brand-01-lightest: #eff0fd;
  --brand-01-light: #b3b6f9;
  --brand-01: #4024d3;
  --brand-01-rgb: 64, 36, 211;
  --brand-01-shade: #18068b;
  --brand-02: #ffffff;
  --brand-03-light: #edffc2;
  --brand-03: #d9ff80;
  --brand-04: #21262a;
  --brand-05: #f2f3f8;
  --brand-06: #ffab58;
  --brand-07: #75cdff;

  --brand-bg: var(--brand-02);

  --alert-40: #f1c21b;
  --alert-30: #0f62fe;
  --alert-20: #1e853b;
  --alert-10: #da1e28;
  --white: #ffffff;
  --black: #000000;
  --warning-bg: #fcf3d1;
  --gray-100: #121519;
  --gray-90: #21262a;
  --gray-80: #34393f;
  --gray-70: #4d5258;
  --gray-60: #696f77;
  --gray-50: #878d96;
  --gray-40: #a2a8b0;
  --gray-30: #c1c6cd;
  --gray-20: #dde0e6;
  --gray-10: #f2f3f8;
  --gray-05: #fafafa;

  --cobalt-30: #cbd2f8;
  --cobalt-60: #f5f6fd;

  --border-color: #dde0e6;
  --line-border: 1px solid #e9ecef;
  --input-border: 1px solid var(--brand-01);
  --input-box-shadow--focus: 0px 0px 0px 3px var(--brand-01-light);
  --input-box-shadow: 0px 1px 0px rgba(221, 224, 230, 0.8);
  --input-height: 48px;
  --input__label-color: #6b7480;
  --input__label-font-size: 14px;

  --background: var(--brand-02);

  --spacing-0: 0;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-64: 64px;

  --font-size-10: 10px;
  --line-height-10: 12px;

  --font-size-12: 12px;
  --line-height-12: 20px;

  --font-size-14: 14px;
  --line-height-14: 24px;
  --font-size-16: 16px;
  --line-height-16: 24px;

  --font-size-18: 18px;
  --line-height-18: 28px;

  --font-size-20: 20px;
  --line-height-20: 28px;

  --font-size-24: 24px;
  --line-height-24: 36px;
  --font-size-28: 28px;
  --line-height-28: 44px;
  --font-size-32: 32px;
  --line-height-32: 48px;
  --font-size-36: 36px;
  --line-height-36: 56px;
  --font-size-42: 42px;
  --line-height-42: 64px;

  --font-size-48: 48px;
  --line-height-48: 72px;
  --font-size-56: 56px;
  --line-height-56: 84px;

  --font-family__primary: 'GT America', sans-serif;
  --font-family__secondary: 'Circular', sans-serif;
  --font-family__mono: 'GT America Mono', sans-serif;

  --font-family__color: var(--gray-60);
  --font-family__headline-color: var(--gray-90);

  /* buttons */
  --button-font-family: var(--font-family__primary);
  --button-font-weight: 500;
  --button-text-transform: none;
  --button-primary-background: var(--brand-01);
  --button-primary-hover: var(--brand-01-shade);
  --button-primary-box-shadow: 0 2px #1a0b80;
  --button-primary-color: var(--brand-02);

  /* secondary */
  --button-secondary-bg: var(W);
  --button-secondary-color: var(--brand-01);
  --button-secondary-border: 1px solid var(--border-color);

  /* rounded => turns into primary */
  --button-bg--rounded: var(--brand-01);
  --button-border--rounded: var(--border-radius);

  /* link */
  --button-link-color: var(--brand-01);

  --bg-primary-box-shadow: 0px 2px 0px var(--brand-01-shade);

  /* info */
  --button-info-bg: var(--brand-01);
  --button-info-bg--hover: var(--brand-01-shade);
  --button-info-bg--focus: var(--brand-01-shade);
  --button-info-box-shadow--focus: 0px 0px 0px 3px
    rgba(var(--brand-01-rgb), 0.32);

  --background: var(--brand-02);
  --header-bar-background: var(--brand-02);
  --text-on-background: var(--gray-90);

  --border-radius: 6px;

  /* Select input */
  --select-input-bg: var(--white);
  --select-input-bg-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2864, 36, 211%29'></polygon></svg>");
  --select-input-box-shadow: var(--input-box-shadow);
  --select-input-border: 1px solid var(--gray-20);
  --select-input-border-radius: 6px;
  --select-background-position: right 10px;
  --select-background-size: 9px 6px;
  --select-input__label-font-size: var(--font-size-14);

  /* Select input flat */
  --select-input-color--flat: var(--gray-90);
  --select-input-border--flat: 1px solid var(--gray-20);
  --select-input-box-shadow--flat: var(--input-box-shadow);

  /* Select input label */
  --select-input__label-color: var(--gray-60);
  --select-input__label-font-size--selected: var(--font-size-10);

  /* inputs */
  --input-border: 1px solid var(--border-color);
  --input-border-focus: var(--input-border);

  /* radio */
  --radio__border: 1px solid var(--gray-20);
  --radio__border--active: 1px solid var(--brand-01);
  --radio__border--focus: var(--input-border);
  --radio__box-shadow: var(--input-box-shadow);
  --radio__box-shadow--active: 0px 1px 0px var(--brand-01);
  --radio__box-shadow--focus: var(--input-box-shadow--focus);
  --radio__padding: 16px;
  --radio__icon: url('../../V2/Shared/Assets/radio-brand.svg') center center
    no-repeat;
  --radio__color: var(--gray-90);
  --radio__color--active: var(--brand-01);
  --radio__background-color: #fff;
  --radio__background-color--active: var(--brand-03);

  /* backlink */
  --backlink-color: var(--font-family__color);

  /* checkbox */
  --check-icon-bg: url('../../V2/Shared/Assets/checkbox-brand.svg') center
    center no-repeat;
  --check-icon-border-radius-radius: 6px;
  --checkbox-size: 26px;
  --checkbox__box-border--focus: var(--input-border);
  --checkbox__box-box-shadow--focus: var(--input-box-shadow--focus);
  --checkbox__box-bg--focus: var(--brand-01-light);
  --checkbox__check-transform--focus: scale(0.65);
  --checkbox__check-border--checked: 2px solid white;

  /* checkbox button */
  --checkbox-button__labelContainer-box-shadow: 0px 1px 0px
    rgba(221, 224, 230, 0.8);
  --checkbox-button__labelContainer-align-items: flex-start;
  --checkbox-button__labelContainer-border: 1px solid var(--gray-20);
  --checkbox-button__labelContainer-border--checked: var(--input-border);
  --checkbox-button__labelContainer-box-shadow--checked: 0px 1px 0px
    var(--brand-01);
  --checkbox-button__labelContainer-bg--checked: var(--brand-03);
  --checkbox-button__border-radius: 6px;
  --checkbox-button__box-border: 1px solid #878d96;
  --checkbox-button__label-font-size: 16px;
  --checkbox-button__label-line-height: 21px;
  --checkbox-button__label-color: var(--brand-01);
  --checkbox-button__label-input-border--focus: var(--input-border);
  --checkbox-button__label-input-box-shadow--focus: var(
    --input-box-shadow--focus
  );
  --checkbox-button__box-after-bg--focus: var(--brand-01-light);
  --checkbox-button__box-after-transform--focus: scale(0.75);
  --checkbox-button__box-after-border-radius--focus: 6px;
  --checkbox-button__description-color: var(--gray-60);
  --checkbox-button__description-color--checked: var(--brand-01);

  /* chat bubble */
  --chat-bubble-bg: var(--brand-01);

  /* chat bubble icon */
  --chat-bubble-icon-color: var(--brand-01);

  /* contact card */
  --contact-card__hours-color: var(--brand-02);
  --contact-card__text-color--light: var(--brand-01);

  /* card */
  --card-border-radius: 6px;
  --card-border: 1px solid var(--border-color);
  --card-box-shadow: 0px 2px 0px rgba(222, 226, 230, 0.8);

  /* contact group */
  --contact-group__support-link: var(--brand-01);

  /* disclaimer */
  --disclaimer-bg: var(--white);
  --disclaimer-color: var(--gray-60);
  --disclaimer-font-size: 12px;

  /* warning disclaimer */
  --disclaimer-warning-bg: var(--warning-bg);
  --disclaimer-warning-color: var(--gray-90);
  --disclaimer-warning-font-size: 14px;
  --disclaimer-warning-line-height: 24px;

  /* entity summary */
  --entity-summary-bg: var(--white);
  --entity-summary-border: 1px solid var(--gray-20);
  --entity-summary-box-shadow: 0px 1px 0px var(--gray-20);
  --entity-summary-border-radius: 6px;
  --entity-summary-margin: 0 0 8px 0;

  /* datepicker */
  --calendar__day-bg--selected: var(--brand-01);
  --calendar__day-bg--hover: rgba(var(--brand-01-rgb), 0.2);
  --calendar__day-border--today: 2px solid rgba(var(--brand-01-rgb), 0.2);
  --calendar-box-shadow: 0px 1px 0px rgba(221, 224, 230, 0.8);
  --calendar-border-radius: 6px;

  /* pulse loader */
  --pulse-loader__bg: linear-gradient(45deg, var(--brand-01), var(--brand-01));

  /* step progress drawer */
  --step-header__progress: var(--brand-06);
  --step-header__progress-bg: none;
  --step-header__border: 1px solid var(--border-color);
  --step-header__sub-text-color: var(--gray-60);
  --step-header__item-bg: var(--brand-02);
  --step-header__item-border: 1px solid var(--border-color);
  --step-header__item-box-shadow: var(--input-box-shadow);

  /* Splash Page Assets */
  --splash-page-assets__main-bg: var(--brand-02);

  --splash-page-assets__main-color: var(--gray-90);
  --splash-page-assets__flat-bg: var(--brand-01);

  /* Phrase loader splash */
  --phrase-loader__mask-bg: linear-gradient(
    to bottom,
    rgba(var(--brand-01-rgb), 1) 0%,
    rgba(var(--brand-01-rgb), 0) 50%,
    rgba(var(--brand-01-rgb), 1) 100%
  );
  --phrase-loader__icon-bg--before: var(--brand-01);
  --phrase-loader__icon-full-border--after: solid var(--brand-01);
  --phrase-loader__text-transform: uppercase;

  /* Blue Page */
  --blue-page__badge-box-shadow: 0px 2px 6px rgba(30, 41, 55, 0.0788893),
    0px 1px 6px rgba(30, 41, 55, 0.0424026);
  --blue-page__badge-bg: var(--brand-06);
  --blue-page__badge-color: var(--white);

  /* Selected payment Plans */
  --selected-payment-plans__main-bg: var(--white);
  --selected-payment-plans__title-color: var(--font-family__headline-color);
  --selected-payment-plans__subtitle-color: var(--gray-60);
  --selected-payment-plans__option-bg: var(--white);
  --selected-payment-plans__option-box-shadow: 0px 2px 0px
    rgba(222, 226, 230, 0.8);
  --selected-payment-plans__option-border: 1px solid var(--gray-20);
  --selected-payment-plans__option-border-radius: 6px;
  --selected-payment-plans__option-padding--medium: 22px 15px 15px;
  --selected-payment-plans__premium-price-amount-font-size--medium: 17px;
  --selected-payment-plans__action-button-border--disabled: 1px solid
    transparent;
  --selected-payment-plans__footer-color: var(--gray-60);
  --selected-payment-plans__effective-date-button-color: var(
    --button-link-color
  );
  --selected-payment-plans__plan-title-color: var(--gray-90);

  /* Toggles */
  /* Toggle Switch */
  --toggle-switch__bg: var(--gray-10);
  --toggle-switch__button-case-color: var(--gray-60);
  --toggle-switch__button-text-color--inactive: var(--gray-60);

  /* form button toggle */
  --button-toggle__bg: white;
  --button-toggle__bg--active: var(--brand-03);
  --button-toggle__border: 1px solid var(--gray-20);
  --button-toggle__border--active: var(--input-border);
  --button-toggle__color--active: 1px solid var(--button-link-color);
  --button-toggle__drop-shadow: var(--input-box-shadow);
  --button-toggle__drop-shadow--active: 0px 1px 0px var(--brand-01);

  /* Congrats */
  --congrats__content-width: 343px;
  --congrats__content--border-radius: 6px;
  --congrats__content-border: 1px solid var(--gray-20);
  --congrats__content--box-shadow: 0px 2px 10px rgba(30, 41, 55, 0.0424026);

  --congrats__next-font-family: var(--font-family__secondary);
  --congrats__next-font-size: var(--font-size-18);
  --congrats__next-line-height: var(--line-height-18);
  --congrats__next-color: var(--gray-90);

  --congrats__policy-font-family: var(--font-family__mono);
  --congrats__policy-font-weight: 500;
  --congrats__policy-font-size: 14px;
  --congrats__policy-color: var(--brand-01);

  --congrats__item-font-size: var(--font-size-16);
  --congrats__item-line-height: var(--line-height-16);

  --congrats_partner-offer--bg: #f5f6fd;
  --congrats__partner-offer--title-font-size: 20px;
  --congrats__partner-offer--title-font-weight: 500;
  --congrats_partner-offer--text-color: #696f77;
  --congrats_partner-offer--offer-font-size: 14px;
  --congrats_partner-offer--offer-line-height: 24px;
  --congrats__partner-offer--box-shadow: 2px 2px 12px rgba(30, 41, 55, 0.12),
    -2px -2px 12px rgba(30, 41, 55, 0.12);
  --congrats__partner-offer--box-border-bottom: 4px solid var(--brand-01);
  --congrats__partner-offer--box-border-radius: 30px 0px 0px 0px;
  --congrats_partner-offer--disclaimer-font-size: 12px;

  /* Confirm Quote */
  --confirm-quote__main-bg: var(--brand-01);
  --confirm-quote__description-color: var(--brand-01);
  --confirm-quote__description-line-height: 14px;
  --confirm-quote__arrow-border: solid var(--brand-01);
  --confirm-quote__increment-line-height: 16px;
  --confirm-quote__increment-line-height--medium: 17px;
  --confirm-quote__cents-line-height: 24px;
  --confirm-quote__cents-line-height--medium: 31px;
  --confirm-quote__item-border: 1px solid var(--border-color);
  --confirm-quote__item-title-font-size: var(--font-size-14);
  --confirm-quote__item-title-line-height: var(--line-height-14);
  --confirm-quote__divider-border: 1px solid #c4c4c4;
  --confirm-quote__policy-divider-border: 1px solid var(--gray-10);
  --confirm-quote__buy-now-button-bg--is-validating: var(
    --button-primary-background
  );
  --confirm-quote__buy-now-button-color--is-validating: var(
    --button-primary-color
  );
  --confirm-quote__buy-now-button-border--is-validating: 1px solid transparent;
  --confirm-quote__sticky-header-box-shadow: 0 2px 4px 0 #6b7480;
  --confirm-quote__cta-mobile-box-shadow: 0 -2px 0 0 rgba(240, 240, 240, 0.5);
  --confirm-quote__cta-mobile-bg: var(--white);
  --confirm-quote__command-line-height: 15px;
  --confirm-quote__bubble-bg: var(--brand-03);

  /* Lead Splash */
  --lead-splash__rating-bg: var(--brand-02);

  /* TODO: this should be rebranded, but skipping for now */
  --lead-splash__featured-text-color: rgba(51, 51, 51);
  --lead-splash__featured-bg: var(--brand-01);
  --lead-splash__logos-position: relative;
  --lead-splash__title-color: var(--brand-01);
  --lead-splash__description-color: #000;
  --lead-splash__featured-text-color: #fff;

  /* payment details */
  --payment-details__payment-bg: var(--white);
  --payment-details__back-color: var(--brand-01);
  --payment-details__title-color: var(--gray-90);
  --payment-details__purchase-summary-color: var(--gray-60);
  --payment-details__due-today-container-color: var(--gray-90);
  --payment-details__policy-bg: rgba(255, 255, 255, 0.12);
  --payment-details__policy-box-shadow: 0 1px 0 rgba(221, 224, 230, 0.8);
  --payment-details__policy-border: 1px solid #dde0e6;
  --payment-details__description-color: var(--gray-60);
  --payment-details__link-color: var(--brand-01);
  --payment-details__ach-headline-color: var(--gray-90);
  --payment-details__payment-input-box-shadow: 0px 1px 4px 0px
    rgba(0, 0, 0, 0.1);
  --payment-details__payment-input-group-color: #6b7480;
  --payment-details__payment-input-container-border: 1px solid #e9ecef;

  --adyen-checkout__payment-method-color: var(--gray-90);
  --adyen-checkout__payment-method-border: 1px solid var(--gray-20);
  --adyen-checkout__payment-method-border-radius: 6px;
  --adyen-checkout__payment-method-box-shadow: none;
  --adyen-checkout__label-color: var(--gray-60);
  --adyen-checkout__payment-method-header-title-color: var(--gray-90);
  --adyen-checkout__button-bg: var(--brand-01);
  --adyen-checkout__button-color: var(--white);
  --adyen-checkout__input-box-shadow: none;
  --adyen-checkout__input-box-shadow--focus: 0 0 0 3px
    rgba(var(--brand-01-rgb), 0.5);
  --adyen-checkout__input-border-color--focus: var(--brand-01);
  --adyen-checkout__input-box-shadow--error: 0 0 0 3px rgba(218, 30, 40, 0.5);
  --adyen-checkout__input-border-color--error: var(--alert-10);
  --adyen-checkout__spinner-color: var(--brand-01);

  /* Typeahead */
  --typeahead-bg--hover: #faf9fc;

  /* Document Signatures */
  --document-signatures__close-button-color: var(--brand-01);
  --document-signatures__progress-bar-bg: var(--brand-06);
  --document-signatures__progress-bar-box-shadow: 0 -1px 1px var(--brand-06);
  --document-signatures__inner-header-align-items: baseline;
  --document-signatures__logo-width: 108px;

  /* ProgressBar loader */
  --progress-bar-bg: #dde0e6;
  --progress-bar__indicator-bg: var(--brand-06);

  /* Full Screen Modal */
  --full-screen-modal__close-btn-color: var(--brand-01);

  /* Vehicle Vin */
  --modal__disclaimer-color: var(--brand-01);
  --modal__disclaimer-bg: #e9ecef;

  /* Confirm Rate Change */
  --rate-change__edit-btn-color: var(--gray-60);
  --rate-change__border-color: var(--border-color);
  --rate-change__title-color: #000;
  --rate-change__description-border: 1px solid var(--gray-20);
  --rate-change__description--box-shadow: 0px 2px 0px rgba(222, 226, 230, 0.8);
  --rate-change__description--border-radius: 6px;

  /* Coverage Packages */
  --coverage-packages__numeral-color: var(--gray-90);
  --coverage-packages__header-conainer-bg: var(--brand-01);

  /* Slider */
  --slider__track-bg-image: linear-gradient(
    -45deg,
    var(--brand-06) 0%,
    var(--brand-06) 100%
  );
  --slider__track-bg: var(--brand-01-shade);
  --slider__mark-bg: #aa8916;
  --slider__mark-unfilled-bg: var(--brand-01-light);

  /* Out of market */
  --out-of-market__cta--color: var(--brand-01);

  /* Edit */
  --edit__header-bg: var(--brand-01);

  /* Stopped */
  --stopped__disclaimer--color: var(--brand-01);
  --stopped__body--color: var(--brand-01);

  /* Logo */
  --step-header__logo-max-width: inherit;
  --step-header__logo-max-height: 55px;
}
