@value medium from '~styles/breakpoints.module.css';

.logo {
  margin: 24px auto;
}

@media medium {
  .logo {
    margin: 50px auto 80px;
  }
}
