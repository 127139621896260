@keyframes shine {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

.loadingBar {
  width: 140px;
  height: 14px;
  border-radius: 3px;
  background-image: linear-gradient(
    90deg,
    #dee2e6 0px,
    rgba(229, 229, 229, 0.8) 40px,
    #dee2e6 80px
  );
  background-size: 600px;
  animation: shine 1s infinite ease-out;
}
