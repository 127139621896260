@value input-height from '~styles/sizes.module.css';

.input {
  appearance: none;
  box-shadow: var(--input-box-shadow);
  font-size: 14px;
  font-family: var(--font-family__primary);
  height: var(--input-height);
  border: var(--input-border);
  border-radius: var(--border-radius);
  padding: 10px 10px 0;
  transition: 0.2s ease all;
  width: 100%;
}

.input:-webkit-autofill + label {
  transform: translateY(-8px);
  font-size: 11px;
}

.input:focus {
  border: var(--input-border-focus);
  outline: 0;
}

.group {
  position: relative;
}

.group label {
  position: absolute;
  top: calc(50% - 10px);
  font-size: var(--input__label-font-size);
  left: 10px;
  transition: all 200ms ease;
  color: var(--input__label-color);
  pointer-events: none;
}

.group input:not(:placeholder-shown) + label,
.group input:focus + label {
  transform: translateY(-8px);
  font-size: 11px;
}

.inputWrapper {
  position: relative;
}

.input[type='number']::-webkit-inner-spin-button,
.input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:disabled {
  cursor: not-allowed;
}
