.banner {
  background: #092f56;
  bottom: 0;
  color: white;
  padding: 16px 28px 16px 16px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 999;
}

.close {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 12px;
}
