@value medium from '~styles/breakpoints.module.css';

.payment {
  background: var(--payment-details__payment-bg);
  overflow-y: scroll;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  width: 100vw;
}

.navigation {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  padding: 0 12px;
  width: 100%;
  z-index: 500;
}

.back {
  color: var(--payment-details__back-color);
  font-family: var(--font-family__secondary);
  font-size: var(--font-size-14);
  font-weight: 500;
  z-index: 1;
}

.buttonContent {
  justify-content: center;
  align-items: center;
  display: flex;
}

.buttonContentIsDeactived {
  opacity: 0;
}

.back div:before {
  border-top-color: white;
}

.icon {
  margin-right: 8px;
}

.main {
  margin: auto;
  margin-top: 16px;
  padding: 0 12px;
  max-width: 390px;
  width: 100%;
}

.logo {
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
}

.logo img {
  width: 100px;
}

@media medium {
  .main {
    padding: 0;
  }
  .logo img {
    width: 150px;
  }
}

.title {
  color: var(--payment-details__title-color);
  font-size: var(--font-size-24);
  line-height: var(--line-height-24);
  font-family: var(--font-family__secondary);
  font-weight: 500;
  margin: 0 0 24px;
  text-align: center;
}

.purchaseSummary {
  margin: 8px 0;
  font-size: var(--font-size-16);
  line-height: var(--line-height-16);
  color: var(--payment-details__purchase-summary-color);
}

.purchaseSummaryTitle {
  font-size: var(--font-size-20);
  line-height: var(--line-height-20);
  font-family: var(--font-family__secondary);
  font-weight: 500;
  color: var(--payment-details__purchase-summary-color);
}

.purchaseSummaryRow {
  display: flex;
  justify-content: space-between;
}

.frequency {
  text-transform: capitalize;
}

.dueTodayContainer {
  color: var(--payment-details__due-today-container-color);
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-20);
  line-height: var(--line-height-20);
  font-family: var(--font-family__secondary);
  font-weight: 500;
  margin-bottom: 12px;
}

.action {
  padding-bottom: 45px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 230px;
}

:global(.adyen-checkout__payment-method),
:global(
    .adyen-checkout__payment-method--card
      .adyen-checkout__payment-method--selected
  ),
:global(.adyen-checkout__payment-method--standalone) {
  background: white !important;
  background-color: white !important;
  border: var(--adyen-checkout__payment-method-border) !important;
  width: 390px !important;
  border-radius: var(--adyen-checkout__payment-method-border-radius) !important;
  color: var(--adyen-checkout__payment-method-color) !important;
  box-shadow: var(--adyen-checkout__payment-method-box-shadow) !important;
}

:global(.adyen-checkout__payment-method) {
  max-width: 100% !important;
  padding: 16px !important;
  margin: auto !important;
}

:global(.adyen-checkout__button) {
  background-image: none !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-size: 14px !important;
  line-height: 16px !important;
  position: relative !important;
  font-family: var(--font-family__secondary) !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  touch-action: manipulation !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
}

:global(.adyen-checkout__button) {
  background-color: var(--adyen-checkout__button-bg) !important;
  color: var(--adyen-checkout__button-color) !important;
}

:global(.adyen-checkout__label .adyen-checkout__label__text) {
  color: var(--adyen-checkout__label-color) !important;
  font-size: var(--font-size-12) !important;
  font-family: var(--font-family__secondary) !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

:global(.adyen-checkout__payment-method__name--selected) {
  display: none !important;
}

:global(.adyen-checkout__button:disabled) {
  background-color: #f8f9fa !important;
  color: #6b7480 !important;
  opacity: 1 !important;
  cursor: not-allowed !important;
}

:global(.adyen-checkout__payment-method__header) {
  margin-bottom: 15px !important;
}

:global(.adyen-checkout__payment-method__header),
:global(.adyen-checkout__payment-method__details) {
  padding: 0 !important;
}

:global(.adyen-checkout__payment-method__header__title) {
  border: none !important;
}

:global(.adyen-checkout__payment-method__header__title img) {
  opacity: 0 !important;
}

:global(.adyen-checkout__payment-method__header__title:before) {
  color: var(--adyen-checkout__payment-method-header-title-color);
  font-family: var(--font-family__secondary);
  font-size: var(--font-size-16);
  font-weight: 500;
  content: 'Card Information' !important;
}

:global(.adyen-checkout__payment-method__image__wrapper:after) {
  border: none !important;
}

:global(.adyen-checkout__input) {
  transition: all 0.1s ease !important;
  box-shadow: var(--adyen-checkout__input-box-shadow);
  border-radius: 3px !important;
}

:global(.adyen-checkout__input--error) {
  outline: 0;
  box-shadow: none !important;
  border-color: var(--adyen-checkout__input-border-color--error) !important;
}

:global(.adyen-checkout__input--focus) {
  outline: 0;
  box-shadow: var(--adyen-checkout__input-box-shadow--focus) !important;
  border-color: var(--adyen-checkout__input-border-color--focus) !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

:global(.adyen-checkout__spinner) {
  /* remove all their styling in regular element */
  -webkit-animation: none !important;
  animation: none !important;
  border: none !important;
  border-radius: unset !important;
  border-top-color: unset !important;
  height: unset !important;
  width: unset !important;

  display: flex;
  justify-content: center;
}

:global(.adyen-checkout__spinner:before) {
  /* c/p from our own spinner into the before */
  animation: spinner 0.6s linear infinite !important;
  border-radius: 50% !important;
  border-top: 2px solid var(--adyen-checkout__spinner-color) !important;
  border-right: 2px solid transparent !important;
  box-sizing: border-box !important;
  content: '';
  height: 16px;
  width: 16px;
}

:global(.adyen-checkout__input--valid) {
  border-bottom-color: #b9c4c9 !important;
}

:global(.adyen-checkout__icon) {
  display: none;
}

/**
div: adyen-checkout__field adyen-checkout__card__holderName
label: adyen-checkout__label
span: adyen-checkout__input-wrapper
input: adyen-checkout__input adyen-checkout__input--text adyen-checkout__input--valid adyen-checkout__card__holderName__input _3JmldYKADXTctIE9oP8lcu adyen-checkout__input--large
*/

.flex {
  display: flex;
}
