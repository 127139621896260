.lineItems {
  margin-top: 12px;
  font-family: var(--font-family__secondary);
  font-weight: 500;
}

.lineItem {
  display: flex;
  justify-content: space-between;
}

.lineItem p {
  margin-bottom: 6px;
  font-size: 14px;
}

.totalLine {
  display: flex;
  justify-content: space-between;
  font-family: var(--font-family__secondary);
  font-weight: 500;
}

.totalLine p {
  font-size: 18px;
  color: var(--gray-90);
}

.closeButton {
  width: 90px;
  height: 48px;
  float: right;
}

.closeButton span {
  font-size: 18px;
}
