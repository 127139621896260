@value medium from '~styles/breakpoints.module.css';

.main {
  top: 0;
  width: 100%;
  z-index: 999;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
}

.overlay {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  cursor: pointer;
}

.wrapper {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 455px;
  max-width: 95%;
  max-height: 95%;
  z-index: 99999;
  display: flex;
}

.container {
  background: var(--brand-02);
  border: 1px solid var(--border-color);
  box-shadow: 0 10px 14px rgba(51, 51, 51, 0.06);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.titleRowContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.badge {
  width: 35px;
  height: 24px;
  margin-right: 11px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: var(--brand-03);
  border-radius: 20px;

  span {
    line-height: var(--line-height-10);
    font-size: var(--font-size-10);
    color: var(--brand-01-shade);
    font-weight: 500;
    text-transform: uppercase;
  }
}

.close {
  height: 38px;
  padding: 12px;
  transform: translate(12px, -8px);
  margin-left: auto;
}

@media medium {
  .container {
    padding: 32px;
  }
}
