.checkbox {
  font-size: var(--font-size-14);
  position: relative;
}

.labelContainer {
  align-items: var(--checkbox-button__labelContainer-align-items);
  background: #fff;
  box-shadow: var(--checkbox-button__labelContainer-box-shadow);
  border-radius: var(--checkbox-button__border-radius);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 12px 14px;
  position: relative;
  border: var(--checkbox-button__labelContainer-border);
}

.label {
  flex: 1;
  font-size: var(--checkbox-button__label-font-size);
  line-height: var(--checkbox-button__label-line-height);
  user-select: none;
  color: var(--checkbox-button__label-color);
}

.box {
  position: relative;
}

.box::before {
  align-items: center;
  border: var(--checkbox-button__box-border);
  border-radius: var(--checkbox-button__border-radius);
  content: '';
  display: block;
  height: 20px;
  margin-right: 10px;
  width: 20px;
}

.box::after {
  content: '';
  height: 20px;
  display: block;
  left: 1px;
  position: absolute;
  top: 1px;
  transform: scale(0);
  width: 20px;
  transition: 0.15s ease-in-out;
  border-radius: var(--checkbox-button__border-radius);
}

.checkbox input:focus + .labelContainer,
.labelContainer:active,
.checkbox input:focus + .labelContainer .box::before {
  border: var(--checkbox-button__label-input-border--focus);
  box-shadow: var(--checkbox-button__label-input-box-shadow--focus);
}

.checkbox input:focus + .labelContainer .box::after {
  background: var(--checkbox-button__box-after-bg--focus);
  transform: var(--checkbox-button__box-after-transform--focus);
  border-radius: var(--checkbox-button__box-after-border-radius--focus);
}

.checkbox .input:checked + .labelContainer .box::after {
  background: var(--check-icon-bg);
  transform: scale(1);
}

.checkbox .input:checked + .labelContainer .box::before {
  border: none;
  box-shadow: none;
}

.checkbox .input:checked + .labelContainer {
  background: var(--checkbox-button__labelContainer-bg--checked);
  border: var(--checkbox-button__labelContainer-border--checked);
  box-shadow: var(--checkbox-button__labelContainer-box-shadow--checked);
}

.checkbox .input {
  position: absolute;
  opacity: 0;
}

.description {
  color: var(--checkbox-button__description-color);
  font-size: var(--font-size-14);
  margin-top: 2px;
}
.checkbox .input:checked + .labelContainer .description {
  color: var(--checkbox-button__description-color--checked);
}
