@value medium from '~styles/breakpoints.module.css';

.consentDisclosure {
  max-width: 726px;
  padding: 25px;
  margin: auto;
  text-align: center;
}

.consentDisclosure p {
  font-size: var(--font-size-12);
  line-height: var(--line-height-12);
  color: var(--gray-60);
}

.phone_consent_disclosure {
  width: 100%;
  padding: 40px;
  margin-top: 80px;
  border-top: 1px solid var(--gray-30);
  background: var(--white);
}

.phone_consent_disclosure p {
  max-width: 975px;
  margin: auto;
}

@media medium {
  .phone_consent_disclosure {
    max-width: 100%;
    padding: 40px 150px;
    margin: auto auto 0;
    flex: none;
  }
}
