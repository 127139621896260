@value medium from '~styles/breakpoints.module.css';

.main .radio {
  font-size: 14px;
  position: relative;
}

.main .label {
  align-items: center;
  background: var(--radio__background-color);
  box-shadow: var(--radio__box-shadow);
  border-radius: var(--border-radius);
  color: var(--radio__color);
  padding: var(--radio__padding);
  border: var(--radio__border);
  cursor: pointer;
  display: flex;
  margin-bottom: 8px;
  position: relative;
  font-weight: 500;
}

.main .box {
  position: relative;
}

.main .box::before {
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 100%;
  content: '';
  display: block;
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.main .box::after {
  background: var(--radio__icon);
  content: '';
  height: 20px;
  display: block;
  left: 1px;
  position: absolute;
  top: 1px;
  transform: scale(0);
  transition: 0.15s ease-in-out;
  width: 20px;
  border-radius: 100%;
}

.main .input:checked + .label .box::after {
  transform: scale(1);
}

.main .input:checked + .label {
  background: var(--radio__background-color--active);
  border: var(--radio__border--active);
  box-shadow: var(--radio__box-shadow--active);
  color: var(--radio__color--active);
}

.main .input {
  position: absolute;
  opacity: 0;
}

.main .input:focus + .label {
  border: var(--radio__border--focus);
  box-shadow: var(--radio__box-shadow--focus);
}

.main .description {
  color: #6b7480;
  font-size: 12px;
  margin-top: 2px;
}

/* flat variant */
.flat {
  background: #fff;
}

@media medium {
  .flat {
    border: 1px solid #e9ecef;
    border-radius: 8px;
  }
}

.flat .radio {
  font-size: 15px;
  position: relative;
}

.flat .label {
  align-items: center;
  border: 1px solid transparent;
  border-bottom: 1px solid #e9ecef;
  color: #313541;
  cursor: pointer;
  display: flex;
  padding: 18px;
  position: relative;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
}

.flat .radio:last-child .label {
  border: 1px solid transparent;
}

.flat .box {
  position: relative;
}

.flat .box::before {
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 100%;
  content: '';
  display: block;
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.flat .box::after {
  background: url('../../Assets/radio.svg') center center no-repeat;
  content: '';
  height: 20px;
  display: block;
  left: 1px;
  position: absolute;
  top: 1px;
  transform: scale(0);
  transition: 0.15s ease-in-out;
  width: 20px;
}

.flat .input:checked + .label .box::after {
  transform: scale(1);
}

.flat .input:checked + .label {
  font-family: var(--font-family__secondary);
  font-weight: 600;
  font-size: 15px;
  color: #0070f0;
  box-shadow: 0px 4px 12px 0px rgba(30, 41, 55, 0.06);
  transform: scale(1.05);
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background: #fff;
  animation: scaling 0.5s ease-in-out;
}

.flat .input {
  position: absolute;
  opacity: 0;
}

.flat .description {
  color: #6b7480;
  font-size: 12px;
  margin-top: 2px;
}
