@value medium from '~styles/breakpoints.module.css';

.base {
  display: none;
}

@media medium {
  .base {
    display: block;
  }

  .hidden {
    display: none;
  }

  .visible {
    display: block;
  }
}
