.container {
  height: 56px;
  background: var(--edit__header-bg);
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  align-items: center;
  z-index: 10;
}

.text {
  color: white;
  font-size: var(--font-size-14);
  line-height: var(--line-height-14);
  line-height: 1.33;
  flex: 3;
  text-align: center;
}

.cta {
  padding-right: 16px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.iconButton {
  height: 24px;
}

.right {
  flex: 1;
}

.close {
  width: auto;
}
