.sectionLabel {
  text-align: left;
  font-weight: 600;
  font-size: 18px;
}

.sections {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  margin-top: 25px;
  margin-bottom: 25px;
  border: var(--card-border);
  border-radius: 8px;
  padding: 20px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.buttons > button:not(:first-child) {
  margin-left: 5px;
}

.group {
  position: relative;
  margin-left: var(--spacing-20);
}

.select {
  appearance: none;
  cursor: pointer;
  height: 48px;
  background-image: var(--select-input-bg-image);
  background-origin: content-box;
  background-color: var(--select-input-bg);
  background-position: right 11px;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  border: var(--select-input-border);
  border-radius: var(--select-input-border-radius);
  box-shadow: var(--select-input-box-shadow);
  font-family: var(--font-family__primary);
  font-size: var(--font-size-16);
  padding: 10px;
}

.select:focus {
  outline: 0;
  border: var(--input-border-focus);
}

.label {
  color: var(--select-input__label-color);
  font-size: var(--font-size-14);
  font-family: var(--font-family__primary);
  display: block;
  width: 100%;
  pointer-events: none;
  text-align: center;
  margin-bottom: 15px;
  text-transform: capitalize;
}
