.errorBanner {
  width: calc(100% - 16px);
  margin: auto;
  background-color: #e84055;
  height: 48px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(30, 41, 55, 0.04),
    0px 1px 4px 0px rgba(30, 41, 55, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
  margin-top: 8px;
}
.message {
  color: rgb(255, 255, 255);
  font-family: var(--font-family__primary);
  font-size: var(--font-size-14);
  line-height: var(--line-height-14);
  font-weight: 600;
  margin-left: 16px;
}
.alert img {
  width: 16px;
}
