@value medium from '~styles/breakpoints.module.css';

.content {
  background: white;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  overflow-y: scroll;
}

.button {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  font-size: 16px;
  font-family: var(--font-family__primary);
  font-weight: 700;
  text-transform: none;
  color: var(--full-screen-modal__close-btn-color);
}

.close {
  display: none;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
}

@media print {
  .content {
    position: absolute;
    overflow-y: inherit;
  }
}

@media medium {
  .button {
    left: auto;
    right: 32px;
    top: 32px;
  }

  .close {
    display: block;
  }

  .icon {
    border: 1px solid #dee2e6;
    border-radius: 50%;
    margin-left: 8px;
  }
}
