@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  justify-content: center;
}

.spinner:before {
  animation: spinner 0.6s linear infinite;
  border-radius: 50%;
  border-top: 2px solid #313541;
  border-right: 2px solid transparent;
  box-sizing: border-box;
  content: '';
  height: 16px;
  width: 16px;
}

.blue:before {
  border-top-color: #0070f0;
}

.white:before {
  border-top-color: white;
}

.yellow:before {
  border-top-color: #ffd105;
}

.gray:before {
  border-top-color: #696f77;
}

.large:before {
  border-top-width: 3px;
  border-right-width: 3px;
  height: 22px;
  width: 22px;
}

.sm:before {
  width: 25px;
  height: 25px;
  margin-top: 10px;
}

.md:before {
  width: 16px;
  height: 16px;
}

.lg:before {
  height: 22px;
  width: 22px;
}
