@value medium from '~styles/breakpoints.module.css';

.main {
  top: 0;
  width: 100%;
  z-index: 999;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
}

.overlay {
  background: rgba(9, 47, 86, 0.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.container {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 99999;
  border: 1px solid #dee2e6;
  box-shadow: 0px 0px 12px 0px rgba(30, 41, 55, 0.12);
  width: 100%;
  height: 100%;
}

@media medium {
  .container {
    border-radius: 12px;
    margin: 80px auto 0;
    max-height: 598px;
    max-width: 919px;
  }
}
