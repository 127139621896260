.fill-brand-01 {
  fill: var(--brand-01);
}
.fill-brand-02 {
  fill: var(--brand-02);
}
.fill-brand-03 {
  fill: var(--brand-03);
}
.fill-brand-04 {
  fill: var(--brand-04);
}
.fill-brand-05 {
  fill: var(--brand-05);
}
.fill-brand-06 {
  fill: var(--brand-06);
}
.fill-alert-20 {
  fill: var(--alert-20);
}
.fill-black {
  fill: var(--black);
}

.stroke-brand-01 {
  stroke: var(--brand-01);
}
.stroke-brand-02 {
  stroke: var(--brand-02);
}
.stroke-brand-03 {
  stroke: var(--brand-03);
}
.stroke-brand-04 {
  stroke: var(--brand-04);
}
.stroke-brand-05 {
  stroke: var(--brand-05);
}
.stroke-brand-06 {
  stroke: var(--brand-06);
}
